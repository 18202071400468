<script>
  import "../../assets/style/icons.css";
  import { fly, fade } from "svelte/transition";
  import Logo from "../components/navs/Logo.svelte";
  import { removeCookie, sendData, setCookie } from "../utils/api";

  const state = {
    phoneOrEmail: "",
    password: "",

    wait: false,
    showPassword: false,
    errors: {},

    mode: "email",
  };

  function send() {
    state.wait = true;
    sendData("/api/login", {
      login: state.phoneOrEmail,
      password: state.password,
      secretcode: "",
    }).then(
      (data) => {
        if (data.status) {
          setCookie(
            "userData",
            window.btoa(encodeURIComponent(JSON.stringify(data.user)))
          );
          window.location.href = data.path;
        } else {
          state.errors.message = data.message;
        }
        state.wait = false;
      },
      (err) => {
        state.errors.message = err.message;
        state.wait = false;
      }
    );
  }
  removeCookie("userData");
  const format = {
    phone: (v) => {
      const cleaned = v.replace(/\D/g, ""),
        trimmed = cleaned.slice(0, 11);
      let formatted = "+7";
      if (trimmed.length > 1) {
        formatted += `-${trimmed.slice(1, 4)}`;
      }
      if (trimmed.length > 4) {
        formatted += `-${trimmed.slice(4, 7)}`;
      }
      if (trimmed.length > 7) {
        formatted += `-${trimmed.slice(7, 9)}`;
      }
      if (trimmed.length > 9) {
        formatted += `-${trimmed.slice(9, 11)}`;
      }
      return formatted;
    },
    email: (v) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(v)) {
        state.errors.login = [["Некорректный email"]];
      } else {
        state.errors.login = [];
      }
    },
  };
  const handler = {
    email: (event) => {
      state.phoneOrEmail = event.target.value;
      format.email(event.target.value);
    },
    phone: (event) => {
      const value = event.target.value;
      state.phoneOrEmail = format.phone(value);

      // Валидация
      if (state.phoneOrEmail.replace(/\D/g, "").length !== 11) {
        state.errors.login = [["Номер телефона должен содержать 11 цифр."]];
      } else {
        state.errors.login = [];
      }
    },
    set: (event) => {
      if (state.mode == "email") {
        handler.email(event);
      } else {
        handler.phone(event);
      }
    },
  };
  const toggleMode = () => {
    state.mode = state.mode == "email" ? "phone" : "email";
    state.phoneOrEmail = "";
    state.errors = {};
  };
</script>

<div class="loginPage">
  <div transition:fade class="text">
    <div class="logo">
      <Logo />
    </div>
    <p>
      Ресурс предназначен для внутреннего использования сотрудниками компании.<br
      />
      Пожалуйста авторизируйтесь!
    </p>
  </div>
  <form
    class="surface form"
    transition:fly={{ y: -30, duration: 300, delay: 300, opacity: 0 }}
    on:submit|preventDefault={send}
  >
    <button
      type="button"
      class="border primary"
      style="transition: all .6s ease;"
      on:click|preventDefault={toggleMode}
      >Войти через {state.mode == "email" ? "телефон" : "почту"}
    </button>
    <div class="divider">
      <p>ИЛИ</p>
    </div>
    <div>
      <p class="label">
        <label class="reset" for="login">
          {state.mode == "email" ? "Почта" : "Телефон"}:
        </label>
      </p>
      <input
        type="text"
        name="login"
        on:input={handler.set}
        bind:value={state.phoneOrEmail}
      />
      {#if state.errors.login}
        <p class="red">
          {#each state.errors.login as err}
            <span>{err}</span>
          {/each}
        </p>
      {/if}
      <p class="label">
        <label class="reset" for="password"> Пароль: </label>
      </p>
      <div class="pass">
        {#if state.showPassword}
          <input
            name="password"
            type="text"
            class="reset"
            bind:value={state.password}
          />
        {:else}
          <input
            name="password"
            type="password"
            class="reset"
            bind:value={state.password}
          />
        {/if}
        <button
          type="button"
          class="text"
          on:click={() => {
            state.showPassword = !state.showPassword;
          }}
        >
          <i class="icon-eye"></i>
        </button>
      </div>
      {#if state.errors.password}
        <p class="red">
          {#each state.errors.password as err}
            <span>{err}</span>
          {/each}
        </p>
      {/if}
    </div>
    <button
      type="submit"
      class="primary filled"
      disabled={state.phoneOrEmail.length < 5 ||
        state.password.length < 5 ||
        state.wait}
    >
      {state.wait ? "Подождите" : "Продолжить"}
    </button>
    {#if state.errors.message}
      <p class="red">
        {#each state.errors.message as err}
          <span>{err}</span>
        {/each}
      </p>
    {/if}
  </form>
  <p class="toReg">Нет аккаунта? <a href="/registration">Регистрация</a></p>
</div>

<style lang="postcss">
  :global(body) {
    min-height: unset !important;
  }
  :global(#app) {
    width: 100%;
    height: 100%;
    min-height: 100dvh !important;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    margin: * * 20px;
  }
  .loginPage {
    max-width: 550px;
    margin: * auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & .form {
      max-width: 297px;
      padding: 40px;
      margin-top: 40px;

      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    & input {
      width: 100%;
    }
  }
  .text {
    text-align: center;
  }
  .label {
    margin: 0 0 4px;
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .pass {
    display: flex;
    margin: * * 14px;
    background: none;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: inherit;
    font: inherit;

    & button {
      size: 36px;
    }
  }

  .red {
    color: red;
    padding: 0;
    margin: 0;
    font-size: 12px;
  }
  .divider {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 2;

      transform: translateY(-50%);

      width: 100%;
      border: 1px gray solid;
    }
    p {
      padding: 0 6px;
      position: relative;
      z-index: 3;
      background-color: white;
    }
  }
  .toReg a {
    text-decoration: underline 1px var(--badge-blue);
    color: var(--badge-blue);
  }
</style>
